import React from 'react'
import Layout from '../../templates/Login'
import { Box } from 'rebass/styled-components'
import Form from '../../modules/Form'

const LoginPage = () => {
    return (
        <Layout>
            <Box minHeight= '80vh' display= 'flex' alignItems= 'center' justifyContent= 'center'>
                <Form type='login'/>
            </Box>
        </Layout>
    )
}

export default LoginPage
